//export const BASE_URL="https://www.themealdb.com/api/json/v1/1";
//export const BASE_URL="https://51.20.83.24:8000/client/api/json/v1/1";
// export const BASE_URL="https://rishraj.pythonanywhere.com/client/api/json/v1/1";
// export const IMAGE_BASEURL="https://rishraj.pythonanywhere.com";
// export const BASE_URL="http://localhost:8000/client/api/json/v1/1";
// export const IMAGE_BASEURL="http://localhost:8000";
export const BASE_URL="https://adminroxy.elixirofo.com/client/api/json/v1/1";
export const IMAGE_BASEURL="https://adminroxy.elixirofo.com";



